import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import TeamModulesModal from "./TeamModulesModal";
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  MODULES_QUERY,
  TEAM_MODULES_QUERY,
  LINK_MODULE_TEAM_MUTATION
} from "./resources/gql";
import { connect } from 'react-redux';
import PeriodCell from "./PeriodCell";
import CriteriaCell from "./CriteriaCell";
import { mapCriteriaData, mapPeriodData, getPeriodKey, getDisabledDays } from "./resources/hooks";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import * as XLSX from 'xlsx';
import Header from "./Header";
import LabelComponent from "../../components/utils/getCompanyLabel";
import { ButtonGroup, ToggleButton  } from "react-bootstrap";
import {exportCoachingReview} from "../../utils/exportUtils";

const getLabelText = key => <LabelComponent val={key}/>

function getYearList(startMonth, isValues) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const array = []
  const index = months.indexOf(startMonth)

  for(let i = index; i < months.length; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  for(let i = 0; i < index; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  return array
}

const getPeriodStatus = (value, periods) => {
  const period = periods.find(period => period.periodValue === value)

  return period ? period.periodStatus : "NORMAL"
}

const getCSVData = (subjects, periods) => {
  let arr = []
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  subjects.map(subject => {
    return subject.metrics.map(metric => {
      return arr.push({
        name: subject.name,
        month: months[metric.periodValue - 1],
        value: metric.value,
        monthStatus: getPeriodStatus(metric.periodValue, periods)
      })
    })
  })

  return [{
    name: "Subject Name",
    month: "Month",
    value: "Value",
    monthStatus: "Month Status"
  }, ...arr]
}

const CoachingRevieScreen = ({ selectedTeam, user, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData, presentation }) => {
  const { data, loading } = useQuery(MODULES_QUERY)
  const [modules, setModules] = useState([])
  const [teamModules, setTeamModules] = useState([])
  const [moduleSubjects, setModuleSubjects] = useState([])
  const [periodMetrics, setPeriodMetrics] = useState([])
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedModules, setSelectedModules] = useState([])
  const [linkLoading, setLinkLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [monthList, setMonthList] = useState([]);
  const [monthValues, setMonthValues] = useState([]);
  const [loadedModule, setLoadedModule] = useState(null)
  const [selectedCompany] = useState(localStorage.getItem("companyId"))
  const [dayTotals, setDayTotals] = useState([]);
  const [disabledDays, setDisabledDays] = useState(new Set());
  const [selectedPeriod, setSelectedPeriod] = useState("current");

  const onLoadComplete = useCallback(
    ({ team: modules }) => {
      setTeamModules(modules.modules)
      let selected = []
      modules.modules.map(({id}) => selected.push(id))
      setSelectedModules(selected)
      if(selected.length) {
        setSelectedModule(selected[0])
        setLoadedModule(modules.modules[0])
      } else {
        setSelectedModule(null)
        setModuleSubjects([])
        setPeriodMetrics([])
        setLoadedModule(null)
      }

      if(refresh) {
        toggleRefresh()
        toggleHasNewData()
      }

      togglePageLoad(false)
    },
    [setTeamModules, togglePageLoad, setSelectedModule, setLoadedModule, setModuleSubjects, setPeriodMetrics, refresh, toggleRefresh, toggleHasNewData]
  );

  const onLinkComplete = useCallback(
    ({ linkModuleTeam: modules }) => {
      setTeamModules(modules.modules)
      let selected = []
      modules.modules.map(module => selected.push(module.id))
      setSelectedModules(selected)
      if(!selected.length) {
        setSelectedModule(null)
        setModuleSubjects([])
        setPeriodMetrics([])
      } else if(!selected.includes(selectedModule)) {
        setSelectedModule(selected[0])
        setLoadedModule(modules.modules[0])
        setModuleSubjects(mapCriteriaData(modules.modules[0].subjects, monthValues))
        setPeriodMetrics(mapPeriodData(modules.modules[0].periodMetrics, monthValues))
        setDisabledDays(getDisabledDays(modules.modules[0].periodMetrics))
      }
      setLinkLoading(false)
      setShowModal(false)
    },
    [setTeamModules, setSelectedModules, setLinkLoading, setShowModal, setSelectedModule, selectedModule, setModuleSubjects, setPeriodMetrics, monthValues]
  );

  const [linkModules] = useMutation(LINK_MODULE_TEAM_MUTATION, {
    onCompleted: onLinkComplete
  });

  const downloadCSV = async () => {
    if(!loadedModule) return;

    await exportCoachingReview({teamModules, monthValues});

    /*
      const csvData = getCSVData(moduleSubjects, periodMetrics)
      if(csvData && csvData.length) {
        let downloadLink = document.createElement("a");
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const ws = XLSX.utils.json_to_sheet(csvData.map(metric => {
          delete metric.__typename

          return metric
        }), {
          skipHeader: true
        });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: fileType});
        let url = URL.createObjectURL(blob);

        downloadLink.href = url;
        downloadLink.download = `[${new Date().toLocaleDateString('en-GB')}] ${loadedModule.name}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

      }
    */
  }

  const saveModuleLinks = useCallback(() => {
   setLinkLoading(true)
   linkModules({
     variables: { id: selectedTeam.id, modules: selectedModules, periodKey: `${getPeriodKey(setMonthValues, selectedPeriod)}` },
   });
  }, [linkModules, selectedModules, setLinkLoading, selectedTeam, selectedPeriod]);

  const [loadTeamModules] = useLazyQuery(TEAM_MODULES_QUERY, {
    variables: {
      id: selectedTeam.id,
      periodKey: `${getPeriodKey(monthValues, selectedPeriod)}`
    },
    onCompleted: onLoadComplete,
  });

  useEffect(() => {
    togglePageLoad(true)
    if(selectedTeam && selectedTeam.id !== 0) loadTeamModules()
  }, [loadTeamModules, selectedTeam, monthValues, togglePageLoad])

  useEffect(() => {
    if(monthValues.length && loadedModule && loadedModule.subjects.length) {
      setModuleSubjects(mapCriteriaData(loadedModule.subjects, monthValues))
      setPeriodMetrics(mapPeriodData(loadedModule.periodMetrics, monthValues))
      setDisabledDays(getDisabledDays(loadedModule.periodMetrics))
    }
  }, [monthValues, loadedModule, setModuleSubjects, setPeriodMetrics])

  useEffect(() => {
    if (loading || !data) return
    setModules(data.reviewModules.filter(module => module.subjects.length))
  }, [setModules, data, loading])

  useEffect(() => {
    if(refresh) {
      togglePageLoad(true)
      if(selectedTeam && selectedTeam.id !== 0) loadTeamModules()
    }
  }, [refresh, togglePageLoad, loadTeamModules, selectedTeam])

  useEffect(() => {
    if(user && user.companies && user.companies.length > 0) {
      const company = user.companies.find(comp => comp.id === selectedCompany)

      if(company) {
        setMonthList(getYearList(company.finalcialYearEnd, false))
        setMonthValues(getYearList(company.finalcialYearEnd, true))
      }
    }
  }, [user, setMonthList, setMonthValues, selectedCompany, selectedTeam])

  const getPeriodMetric = period => {
    const { periodMetrics } = loadedModule

    const metric = periodMetrics.find(metric => metric.periodValue === `${period}`)

    return metric ? metric : {
      id: null,
      periodStatus: "NORMAL",
      periodValue: period,
      periodKey: getPeriodKey(monthValues,selectedPeriod),
    }
  }

  const getCriteriaMetric = (period, subject) => {
    const metric = subject.metrics.find(metric => metric.periodValue === `${period}`)

    return metric ? metric : {
      id: null,
      value: 0,
      periodValue: period,
      periodKey: getPeriodKey(monthValues,selectedPeriod)
    }
  }

  const onPeriodCellChange = ({ index: day, state }) => {
    let totals = dayTotals
    if (state === 2) {
      const nextSet = new Set(disabledDays);
      nextSet.add(day);
      if(totals[day - 1]) {
        totals[day - 1] = {
          day: day,
          count: totals[day - 1] && totals[day - 1].count,
          visible: false
        }
      }
      setDayTotals(dayTotals)
      setDisabledDays(nextSet);
    } else {
      const nextSet = new Set(disabledDays);
      nextSet.delete(day);
      if(totals[day - 1]) {
        totals[day - 1] = {
          day: day,
          count: totals[day - 1] && totals[day - 1].count,
          visible: true
        }
      }
      setDayTotals(dayTotals)
      setDisabledDays(nextSet);
    }
  };

  const updateMetricData = (metrics, subjectId) => {
    setModuleSubjects(moduleSubjects.map(subject => {
      return subject.id === subjectId ? {
        ...subject,
        metrics
      } : subject
    }))
    loadTeamModules();
  }

  const getPeriodTotal = period => {
    let total = 0
    moduleSubjects.map(subject => {
      const metric = subject.metrics.find(metric => metric.periodValue === `${period}`)

      return total += metric.value
    })
    return total
  }

  const changeLoadedModule = useCallback(
    (id) => {
      const module = teamModules.find(module => module.id === id)

      setTeamModules(teamModules.map(mod => {
        return mod.id !== selectedModule ? mod : {
          ...mod,
          periodMetrics: periodMetrics,
          subjects: moduleSubjects
        }
      }))

      if(module) {
        setLoadedModule(module)
        setSelectedModule(id)
        setModuleSubjects(mapCriteriaData(module.subjects, monthValues))
        setPeriodMetrics(mapPeriodData(module.periodMetrics, monthValues))
        setDisabledDays(getDisabledDays(module.periodMetrics))
      } else {
        setLoadedModule(null)
        setSelectedModule(null)
        setModuleSubjects([])
      }
    },
    [teamModules, setTeamModules, setLoadedModule, setSelectedModule, setModuleSubjects, setPeriodMetrics, setDisabledDays, selectedModule, moduleSubjects, monthValues, periodMetrics]
  )


  useEffect(() => {
    if (refresh) {
      togglePageLoad(true)
      loadTeamModules()
    }
  }, [loadTeamModules, selectedPeriod, refresh, togglePageLoad])

  const setPeriod = period => {
    setSelectedPeriod(period);
    setLoadedModule(null);
    if (selectedTeam && selectedTeam.id !== 0) {
      togglePageLoad(true);
    }
  }

  return (
    <Fragment>
      <SubHeaderComponent>
        <Col className="text-right childenSpace">
          <div>
          <ButtonGroup toggle className="ml-1">
                <ToggleButton
                  type="radio"
                  variant="secondary"
                  name="createType"
                  value="0"
                  checked={selectedPeriod === "previous"}
                  onChange={() => setPeriod("previous")}
                >
                  {getLabelText("previous")}
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  variant="secondary"
                  name="createType"
                  value="0"
                  checked={selectedPeriod === "current"}
                  onChange={() => setPeriod("current")}
                >
                  {getLabelText("current")}
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  variant="secondary"
                  name="createType"
                  value="1"
                  checked={selectedPeriod === "next"}
                  onChange={() => setPeriod("next")}
                >
                  {getLabelText("next")}
                </ToggleButton>
              </ButtonGroup>
          </div>
          <div className={"refreshBtn"}>
            <RealtimeRefreshComponent
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={toggleRefresh}
            />
          </div>
          {selectedTeam.id !== 0 && (
            <TeamModulesModal
              modules={modules}
              modulesLoading={loading}
              selectedModules={selectedModules}
              setSelectedModules={setSelectedModules}
              team={selectedTeam}
              linkLoading={linkLoading}
              saveModuleLinks={saveModuleLinks}
              showModal={showModal}
              setShowModal={setShowModal}
              downloadCSV={downloadCSV}
            />
          )}
        </Col>
      </SubHeaderComponent>
      <div className="grey-header-space container-left">
        <Header
            presentation={presentation}
            selectedModule={selectedModule}
            changeLoadedModule={changeLoadedModule}
            teamModules={teamModules}
            loadedModule={loadedModule}
        />
        <Row className="mt-3 mb-5 coaching-rev-scroller">
          <Col>
            {loadedModule
                ? (
                    <Fragment>
                      <Row className={"align-items-baseline w-100 mb-3"}>
                        <Col xs={7}></Col>
                        <Col xs={5}>
                          <Row className="flex-nowrap">
                            {loadedModule && monthValues.map((period, i) => (
                                <PeriodCell
                                    result={getPeriodMetric(period)}
                                    teamId={selectedTeam.id}
                                    moduleId={loadedModule.id}
                                    metrics={periodMetrics}
                                    setPeriodMetrics={setPeriodMetrics}
                                    key={period}
                                    day={period}
                                    today={new Date()}
                                    onCellChange={onPeriodCellChange}
                                    monthList={monthList}
                                    index={i}
                                />
                            ))}
                          </Row>
                        </Col>
                      </Row>
                      {loadedModule &&
                      moduleSubjects.map((subject, i) => (
                          <Row
                              className={"align-items-center w-100 mb-2"}
                              key={i}
                          >
                            <Col xs={7}>
                              <table className="w-100 basic-tbl">
                                <tbody>
                                <tr>
                                  <td width="5%" className="py-1 px-2">
                                    {(i + 1)}.
                                  </td>
                                  <td className="py-1 px-2">
                                    {subject.name}
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </Col>
                            <Col xs={5}>
                              <Row className="flex-nowrap">
                                {monthValues.map((period) => (
                                    <CriteriaCell
                                        key={period}
                                        day={period}
                                        subjectId={subject.id}
                                        teamId={selectedTeam.id}
                                        disabledDays={disabledDays}
                                        result={getCriteriaMetric(period, subject)}
                                        metrics={subject.metrics}
                                        updateMetricData={updateMetricData}
                                        dayTotals={dayTotals}
                                        moduleId={selectedModule}
                                        monthValues={monthValues}
                                    selectedPeriod={selectedPeriod}
                                    />
                                ))}
                              </Row>
                            </Col>
                          </Row>
                      ))}
                      <Row
                          className={"align-items-center w-100 mb-2"}
                      >
                        <Col xs={7}>
                          <table className="w-100">
                            <tbody>
                            <tr>
                              <td>
                                <b>{getLabelText("total")}:</b>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </Col>
                        <Col xs={5}>
                          <Row className="flex-nowrap">
                            {monthValues.map((period) => (
                                <div className="review-cell text-center font-weight-bold px-1 mr-3">
                                  <b>{getPeriodTotal(period)}</b>
                                </div>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Fragment>
                ) : (
                    <Row
                        className={"align-items-center w-100 mb-2"}
                    >
                      <Col>
                        <table className="w-100">
                          <tbody>
                          <tr>
                            <td className="text-cente">
                              <label className="cdi-blue-txt ml-0">{getLabelText("no_modules_error")}</label>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                )}
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CoachingRevieScreen);
